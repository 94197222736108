.modal-controller {
  position: absolute;
  background-color: #f3f3f3;
  border: 2px solid white;
  bottom: 50vh;
  left: 35vw;
  width: 35vw;
  height: 35vh;
}
.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: start;
  height: 20vh;
  padding-left: 4%;
}

.modal-header {
  border-bottom: 2px solid white;
  padding-left: 1rem;
}
