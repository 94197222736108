.login-bg {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-box {
  width: 424px;
  height: 314px;
  top: 249px;
  left: 431px;
  border: 2px solid grey;
  border-radius: 15px;
}

.login-box > div {
  padding-top: 6vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.login-text-box1 {
  width: 354px;
  height: 40px;
  margin-bottom: 5vh;
  background-color: #f3f3f3;
  border: 1px solid grey;
  border-radius: 15px;
}

.login-text-box2 {
  width: 354px;
  height: 40px;
  background-color: #f3f3f3;
  border: 1px solid grey;
  border-radius: 15px;
}

.login-header {
  width: 178px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8vh;
}

.image-1 {
  padding-right: 1vw;
}

.forgot-password {
  color: grey;
  display: flex;
  align-items: end;
  justify-content: end;
}

.loginSubmit {
  color: #f3f3f3;
  background-color: black;
  border-radius: 15px;
  height: 5vh;
  width: 354px;
}

.submit-button-box {
  padding: 7px;
}
