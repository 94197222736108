.grades-page {
  display: flex;
  position: relative;
  /* overflow: scroll; */
}
.gradesHeader-main {
  height: 15vh;
  position: relative;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 3px solid #f3f3f3;
}
.search-Header {
  border-radius: 12px;
}
.button-Header {
  border-radius: 12px;
  background-color: #242323;
  color: white;
  /* width: 13vw; */
  width: 83%;
  height: 3vh;
  margin: 1rem;
}
.headerClasss {
  padding: 1rem;
}
.header-body {
  display: flex;
  flex-direction: column;
  width: 80vw;
}
.modal-controller {
  position: absolute;
  background-color: #f3f3f3;
  border: 2px solid white;
  bottom: 50vh;
  left: 35vw;
  width: 35vw;
  height: 35vh;
}
.modal-header {
  border-bottom: 2px solid white;
  padding-left: 1rem;
}

.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: start;
  height: 20vh;
  padding-left: 1rem;
}

.gades-footer {
  /* width: 100%; */
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
.grades-footer-btn {
  display: flex;
  justify-content: space-evenly;
  padding: 1%;
  width: 15vw;
}
.modal-btn-add {
  border-radius: 10px;
  border: 1px;
  width: 7vw;
  background-color: white;
}
.modal-btn-add:hover {
  cursor: pointer;
}
.modal-btn-cancel {
  border-radius: 10px;
  border: 1px;
  width: 7vw;
  background-color: white;
}
.modal-btn-cancel:hover {
  cursor: pointer;
}

.input-modal {
  border-radius: 12px;
}
@media (max-width: 768px) {
  .header-body {
    width: 100vw !important;
  }
  .modal-controller {
    width: 70vw !important;
    left: 15vw !important;
  }
  .selector-box {
    display: none;
  }
}
