.sideBar-Main {
  width: 20vw;
  background-color: #f3f3f3;
  min-height: 100vh;
  overflow: scroll;
  flex: 1;
}

@media (max-width: 768px) {
  .sideBar-Main {
    display: none;
  }
  .sideBar-header {
    width: 100%;
  }
}

.sideBar-header {
  display: flex;
  justify-content: center;
  height: 15vh;
  /* width: 40vh; */
  /* align-items: center; */
}

.header-ImageSidebar {
  width: 5vw;
  height: 6vh;
  padding: 3%;
}
.header-text-sidebar {
  width: 4vw;
  height: 4vh;
  padding-left: 0.5rem;
}

.sidebar-Buttons {
  display: flex;
  flex-direction: column;
  /* border: 2px; */
  padding-left: 2vw;
  justify-content: space-evenly;
}

.button-primary-sidebar {
  width: 10vw;
  height: 4vh;
  border-radius: 15px;
}

.controller-header {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
