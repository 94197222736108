.grades-page {
  display: flex;
  position: relative;
}
.gradesHeader-main {
  height: 15vh;
  position: relative;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 3px solid #f3f3f3;
}
.search-Header {
  border-radius: 12px;
}
.button-Header {
  border-radius: 12px;
  background-color: #242323;
  color: white;
  /* width: 13vw; */
  width: 83%;
  height: 3vh;
  margin: 1rem;
}
.headerClasss {
  padding: 1rem;
}
.header-body {
  display: flex;
  flex-direction: column;
  width: 80vw;
}
.modal-controller {
  position: absolute;
  background-color: #f3f3f3;
  border: 2px solid white;
  bottom: 50vh;
  left: 35vw;
  width: 35vw;
  height: 35vh;
}
.modal-header {
  border-bottom: 2px solid white;
  padding-left: 1rem;
}

.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: start;
  height: 20vh;
  padding-left: 1rem;
}

.input-modal {
  border-radius: 12px;
}
