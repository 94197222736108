/* .card-Body {
  position: relative;
  top: 2px;
  left: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 2px solid #f3f3f3;
  background-color: #f3f3f3;
  padding: 2rem;
  margin: 10px;
  height: 5vh;
  width: 5vw;
}
.card-body-parent {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  height: 85vh;
  display: flex;
  flex-wrap: wrap;
} */

.card-Body {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 2px solid #f3f3f3;
  background-color: #f3f3f3;
  padding: 2rem;
  margin: 10px;
  height: calc(20vh - 20px); /* Adjust height as needed */
  width: calc(20vw - 20px); /* Adjust width as needed */
}

.icon-common {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: 3%;
  right: 3%;
}
.main-body {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-body-parent {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  height: 85vh;
  padding: 2%; /* Added padding to account for the margin of .card-Body */
  box-sizing: border-box; /* Ensure padding is included in the total width and height */
  overflow: auto;
}
